.form {
  padding: 20px 0;

  &__row {
    padding: 0 40px;
  }
}

.createEdit-form {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;

  &__inline-item {
    margin-left: -5px;
    margin-right: 15px;
    display: inline-block !important;
  }

  &__tail {
    margin-left: 10px;
  }

  .ant-form-item {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
  }

  .ant-form-item-label {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }

  .ant-form-item-control-wrapper {
    display: block;
    box-sizing: border-box;
    width: 58%;
  }

  &__btns {
    flex-basis: 100% !important;
    text-align: center;
    margin-top: 30px;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__form-table {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 20px 0;
    flex-basis: 100% !important;
  }

  &__full-screen {
    flex-basis: 100% !important;
    flex-wrap: wrap;

    .ant-form-item-control-wrapper {
      width: 100%;
    }
  }

  &__full-screen-left {
    flex-basis: 100% !important;
    margin-right: 4%;
    margin-left: 4%;

    .ant-form-item-control-wrapper {
      width: 100%;
    }
  }

  &__td-pre-line {
    white-space: pre-line !important;
  }
}


.modal-form-point {
  .ant-form-item-control-wrapper {
    .ant-form-item-control {
      display: flex;
      flex-direction: row;
      align-content: center;

      .ant-form-extra {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }
}
