.ant-table-thead > tr > th {
  font-weight: 400 !important;
  border-bottom: none !important;
}

.ant-table-small .ant-table-thead > tr > th {
  background: $table-th-color !important;
  border: none !important;
}

.ant-table-tbody > tr > td {
  border-bottom-color: #eaeef4 !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 15px 5px;
  overflow-wrap: break-word;
  text-align: center !important;
}

.ant-table-placeholder {
  border-top: none !important;
  border-bottom: none !important;
}

.ant-select-disabled .ant-select-selection {
  background-color: #f5f7fa !important;
  color: $font-color !important;
}

.has-error {
  // input
  .ant-input,
  .ant-input:hover,
  .ant-input-affix-wrapper .ant-input,
  .ant-input-affix-wrapper .ant-input:hover,
  .ant-input-number,
  .ant-time-picker-input {
    border-color: $tip-color;
  }

  // select
  .ant-select-selection {
    border-color: $tip-color;
  }

  // form
  .ant-form-explain,
  .ant-form-split {
    color: $tip-color;
  }
}

// date
.ant-input[disabled] {
  background-color: #f5f7fa !important;
  color: $font-color !important;
}

.ant-table-small {
  border: 0 !important;
}

.ant-table-title {
  margin: 0 10px;
}

.bg-red {
  background-color: rgba(255, 0, 0, 0.2);

  &:hover > td {
    background-color: rgba(255, 0, 0, 0.4) !important;
  }
}