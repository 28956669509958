.cancel-btn {
  border-color: $btn-cancel-border-color;
  background-color: $btn-cancel-color;
  color: $tip-color;

  &:hover,
  &:focus {
    color: $white;
    background-color: $tip-color;
    border-color: $tip-color;
  }
}

.basis-btn {
  border-color: #b3d8ff;
  background-color: #ecf5ff;
  color: $primary-color;

  &:hover,
  &:focus {
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
