@import './reset-andt.scss';
@import './form.scss';
@import './btn.scss';
@import './animation.scss';

.tip-text {
  color: $tip-color;
}

.red-text {
  color: $red;
}

.green-text {
  color: $green-color;
}

.warning-text {
  color: #faad14;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}


.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.padding-20 {
  padding: 20px;
}

.omit-3 {
  max-height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  word-break: break-all;
  text-align: left;
}

.omit-1 {
  height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  word-break: break-all;
  text-align: left;
}

.w-100 {
  width: 100%;
}

.no-padding-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-pagination.ant-table-pagination {
    margin-right: 10px;
  }
}

.center-btns {
  flex-basis: 100% !important;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.text-btn-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_text {
    font-size: 12px;
  }
}

.body-container {
  padding: 20px;
}

.card-edit {
  margin: 20px !important;
  border-radius: 5px !important;

  .ant-card-body,
  .ant-card-small {
    padding: 15px !important;
  }
}

.success-text {
  color: $green-color;
}

.error-text {
  color: $tip-color;
}

.word-nowrap {
  white-space: nowrap;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.font-red {
  color: $tip-color;
}

.alert-text {
  color: $tip-color;
  font-weight: bold;
}


// 横排flex
.flex-row {
  display: flex;
  flex-direction: row;
}

// 竖排flex
.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-middle {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-bottom {
  justify-content: flex-end;
}

.justify-top {
  justify-content: flex-start;
}

.align-center {
  align-items: center;
}

.align-right {
  align-items: flex-end;
}

.align-left {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

// flex填充
.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.list-pic-tooltip {
  img {
    width: 150px;
    max-height: 150px;
  }
}


.lh-30 {
  line-height: 30px;
}

.layout-operation {
  position: absolute;
  right: 20px;
  top: -45px;
  z-index: 1000;

  > button {
    margin-left: 10px;
  }
}

.text-left {
  text-align: left;
}

.note-template-content{
  table{
    width: 100%;
    td {
      height: 20px;
    }
  }
}