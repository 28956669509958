// 基础色
$white: #fff;
$black: #000;
$gray: #f2f2f2;
$red: red;
$border-black: rgba(0, 0, 0, 0.09);
$border-white: #e6e6e6;

// ui主色
$primary-color: #40a2fe;
$font-color: #333;
$btn-color: $primary-color;
$btn-cancel-color: #fff5f1;
$btn-cancel-border-color: #ffd0bd;
$tip-color: #f55716;
$describe: #b2b2b2;
$table-th-color: #f6fbff;

// 全局背景
$body_color: #eaeef4;

// 导航
$navigation: #23283f;
$navigation_selected: #181c30;

// 组件颜色.
$green-color: #52c41a;

// 警告提示.
$tip-red: #cf1322;

// table td警告背景色.
$td-warnning-color: rgba(248, 205, 70, 1);

@import 'assets/css/index.scss';

#root {
  height: 100%;
}

.global-tip {
  position: absolute;
  text-align: center;
  z-index: 1000;
  width: 100%;

  button {
    padding: 0;
  }

  p {
    margin: 0;
  }
}

.spin-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.spin-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mian-tool {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 1000;

  > button {
    margin-left: 10px;
  }
}
